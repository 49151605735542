@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://cdn.syncfusion.com/ej2/bootstrap5.css");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #ececec !important;
}

.slick-dots-test {
  display: none;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: white !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-cell {

  border-bottom: 1px dotted rgb(81, 81, 81) !important;
}

.css-1xn3edc {
  margin-bottom: 0 !important;
}

.css-y0u0hj {
  padding: 0 !important;
}

.css-tvc4j0 {
  margin: 0 !important;
}

.css-8u52my-MuiButtonBase-root-MuiIconButton-root {
  color: red !important;
}

element {}

.css-1ny7bi9-MuiButtonBase-root-MuiButton-root {
  color: red !important;
}

.css-1vszfpd-MuiTypography-root {
  color: red !important;
}

element {}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
}

.addNew {
  border: 1px solid red !important;
}

.spanAddNew {

  font-size: 20px;
  font-weight: 400;
  color: red;
}


.css-tvc4j0 {

  padding: 0 !important;

}

element {}

.css-1513gcf-MuiButtonBase-root-MuiButton-root {
  border: none !important;
}

.css-1pnjw6q .pro-item-content>p {
  font-size: 20px !important;

}

.css-1taozsw-MuiTypography-root {

  color: #d3d8e5 !important;

}

.button-color {
  color: red;
  font-size: 20px;
}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root {
  position: absolute !important;
  right: 0 !important;
  top: -50px !important;
}

.css-196n7va-MuiSvgIcon-root {
  color: black !important;
}

.css-196n7va-MuiSvgIcon-root {

  color: red !important;
  font-size: 20px !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-row--editing .MuiDataGrid-cell {
  box-shadow: none;
  background-color: #c9c3c3 !important;
}

.ola {
  background-image: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(164, 25, 25, 0.56)), url('../public//login.jpg');
  height: 500px;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: top;
  /* position: relative; */
  background-size: cover;
}

.pro-sidebar-inner {
  background-image: linear-gradient(0deg, rgba(19, 16, 18, 0.3), rgba(0, 0, 0, 0.9)), url(http://localhost:3000/static/media/login.0debe33cace723ab6e00.jpg) !important;
  background-size: cover !important;
  background-position: center !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main>div:nth-child(3) {
  display: none;
}

.swal-button--confirm {
  background-color: #d84444;
}

.swal-button:not([disabled]):hover {
  background-color: #e26161;
}

.swal-button:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(165, 43, 43, 0.29);
}

@media (min-width: 481px) and (max-width:1536px) {
  .ola>div {
    position: relative !important;
    top: 20% !important;
    left: 6% !important;
    width: 80vw !important;
  }
}

@media (min-width: 320px) and (max-width:480px) {
  .ola>div {
    position: relative !important;
    top: 20% !important;
    left: 6% !important;
    width: 70vw !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #ececec !important;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: white !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-cell {

  border-bottom: 1px dotted rgb(81, 81, 81) !important;
}

.css-1xn3edc {
  margin-bottom: 0 !important;
}

.css-y0u0hj {
  padding: 0 !important;
}

.css-tvc4j0 {
  margin: 0 !important;
}

.css-8u52my-MuiButtonBase-root-MuiIconButton-root {
  color: red !important;
}

element {}

.css-1ny7bi9-MuiButtonBase-root-MuiButton-root {
  color: red !important;
}

.css-1vszfpd-MuiTypography-root {
  color: red !important;
}

element {}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
}

.addNew {
  border: 1px solid red !important;
}

.spanAddNew {

  font-size: 20px;
  font-weight: 400;
  color: red;
}


.css-tvc4j0 {

  padding: 0 !important;

}

element {}

.css-1513gcf-MuiButtonBase-root-MuiButton-root {
  border: none !important;
}

.css-1pnjw6q .pro-item-content>p {
  font-size: 20px !important;

}

.css-1taozsw-MuiTypography-root {

  color: #d3d8e5 !important;

}

.button-color {
  color: red;
  font-size: 20px;
}

.css-13qp4b7-MuiButtonBase-root-MuiButton-root {
  position: absolute !important;
  right: 0 !important;
  top: -50px !important;
}

.css-196n7va-MuiSvgIcon-root {
  color: black !important;
}

.css-196n7va-MuiSvgIcon-root {

  color: red !important;
  font-size: 20px !important;
}

.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-row--editing .MuiDataGrid-cell {
  box-shadow: none;
  background-color: #c9c3c3 !important;
}

.ola {
  background-image: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(164, 25, 25, 0.56)), url('../public//login.jpg');
  height: 500px;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: top;
  /* position: relative; */
  background-size: cover;
}

.pro-sidebar-inner {
  background-image: linear-gradient(0deg, rgba(19, 16, 18, 0.3), rgba(0, 0, 0, 0.9)), url(http://localhost:3000/static/media/login.0debe33cace723ab6e00.jpg) !important;
  background-size: cover !important;
  background-position: center !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main>div:nth-child(3) {
  display: none;
}

.swal-button--confirm {
  background-color: #d84444;
}

.swal-button:not([disabled]):hover {
  background-color: #e26161;
}

.swal-button:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(165, 43, 43, 0.29);
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

@media (min-width: 481px) and (max-width:1536px) {
  .ola>div {
    position: relative !important;
    top: 20% !important;
    left: 6% !important;
    width: 80vw !important;
  }
}

@media (min-width: 320px) and (max-width:480px) {
  .ola>div {
    position: relative !important;
    top: 20% !important;
    left: 6% !important;
    width: 70vw !important;
  }
}

.pro-sidebar {
  position: fixed !important;
}

.main-div,
.css-zjqymt {
  padding: 10px 30px 10px 300px;

}

.userdetails {
  background-color: #fff;
  border-radius: 5px;
  margin: 10px 0;
}

.userdetailsborder {
  background-color: #959595;
  /* height: 20px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.table {
  width: 100%;
  text-align: left;
  color: #000;
  padding-right: 10px;
  background-color: #fff;
  padding: 10px;
}

.btn-submit {
  margin-top: 20px !important;
  background: #cf0a0a !important;
}

.btn-user-details {
  background: #cf0a0a;
  border: none;
  padding: 5px 10px;
  color: #fff;
  box-shadow: 2px 2px 10px #333;
  border-radius: 3px;
  font-weight: bold;
}

.userfounds {
  background-color: #fff;
  border-radius: 5px;
  margin: 10px 0;
}

.userfoundsborder {
  background-color: #fcdccd;
  /* height: 20px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-right: 10px;
}

.viewbtn {
  color: #3b82f6 !important;
  margin: 0 5px !important;
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
}

.btn-view {
  background-color: #000000 !important;
  margin: 0 5px !important;
  font-weight: bold;
  padding: 5px 10px;
  box-shadow: none !important;

}

.btn-view span {
  text-transform: initial;
  font-weight: bold;
}

.div-btn-cancel {
  display: flex;
  width: 100%;
  justify-content: end;
}

.titlepage {
  background-color: #e5e7eb;
  color: #707070;
  border-radius: 4px;
  padding: 10px 20px;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
  color: #000;
}

.css-wnx1cx-MuiButtonBase-root-MuiButton-root {
  color: #fff !important;
}

/* ==================================================== */
.select {
  font-family:
    'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;
  width: 100%;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}


/* LABEL ======================================= */
.select-label {
  color: rgb(121, 121, 121);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus~.select-label,
.select-text:valid~.select-label {
  color: #cf0a0a;
  top: -20px;
  transition: 0.2s ease all;
  font-size: 14px;
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
  width: 100%;
}

.select-bar:before,
.select-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #cf0a0a;
  transition: 0.2s ease all;
}

.select-bar:before {
  left: 50%;
}

.select-bar:after {
  right: 50%;
}

/* active state */
.select-text:focus~.select-bar:before,
.select-text:focus~.select-bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* ==================================================== */
.css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root {
  color: #151414 !important;
}

.css-9425fu-MuiOutlinedInput-notchedOutline {
  border-color: rgba(19, 18, 18, 0.23) !important;
}

.css-wied2b-MuiFormLabel-root-MuiInputLabel-root {
  color: black;
}

.css-ists20 {
  padding: 10px 20px 10px 300px;
}

.MuiFormControl-root {
  width: 100% !important;
  margin: -4px !important;
}

.e-multi-select-wrapper {
  background: #ececec !important;
  border: none !important;
  border-bottom: 2px solid #555 !important;
}

.css-1ptyyyq-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(9, 9, 9, 0.7) !important;
}

.css-wied2b-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(11, 11, 11, 0.7) !important;
}

.div-btn-submit {
  display: flex;
  justify-content: end;
  align-items: end;
}

.text-danger {
  color: black;
}

.css-1ptyyyq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.updateImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border: 1px solid #ececec !important;
}
.testt{
  margin: 20px 10px ;
}

.MuiButtonBase-root {
  color: red !important;
}